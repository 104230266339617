
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }


.page-center {
  text-align: center; }

.review-holder {
  text-align: center;
  width: auto;
  min-width: 800px;
  display: inline-block;
  padding: 0  20px; }

.review-card {
  position: relative;
  height: 300px;
  width: 500px;
  background-color: #fff;
  display: inline-flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 15px;
  text-align: left;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 10px;
  cursor: pointer; }
  .review-card__user-info {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
    .review-card__user-info__profile-icon {
      height: 50px;
      width: 50px;
      margin-right: 15px;
      cursor: pointer; }
    .review-card__user-info__user-txt {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 1 1 auto; }
      .review-card__user-info__user-txt--username {
        margin: 0;
        font-weight: bold;
        margin-bottom: 5px; }
      .review-card__user-info__user-txt--date {
        margin: 0;
        font-size: .8em;
        opacity: .5; }
  .review-card__review-info {
    flex: 1 1 auto;
    display: flex; }
    .review-card__review-info__score {
      height: 100%;
      width: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .review-card__review-info__score--data {
        font-size: 1.8em;
        font-weight: bold;
        margin: 0; }
    .review-card__review-info__movie-poster {
      flex: 0 0 auto;
      margin-right: 15px;
      height: 100%;
      width: 140px;
      border-radius: 4px;
      background-color: #eee;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      overflow: hidden;
      cursor: pointer; }
      .review-card__review-info__movie-poster img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .review-card__review-info__content--header {
      font-weight: bold;
      text-transform: capitalize;
      margin: 0 0 15px 0;
      font-size: 1.2em; }
    .review-card__review-info__content--body {
      margin: 0; }
    .review-card__review-info__content--comment {
      margin: 10px 20px;
      position: absolute;
      bottom: 0;
      right: 0; }
      .review-card__review-info__content--comment i {
        margin-left: 4px; }

.user-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 10px;
  background: -webkit-linear-gradient(left, #29d0be 0%, #6cb8ea 100%);
  background: linear-gradient(90deg, #29d0be 0%, #6cb8ea 100%);
  color: #fff;
  text-transform: capitalize;
  overflow: hidden; }
  .user-icon:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  .user-icon p {
    font-weight: bold; }
  .user-icon img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1000;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }
  .modal .modal-content {
    background-color: #fefefe;
    border-radius: 10px;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    width: 600px;
    /* Could be more or less, depending on screen size */ }
    .modal .modal-content .close-icon {
      margin: 0;
      font-size: 30px;
      cursor: pointer;
      text-align: right; }
    .modal .modal-content .comment-form {
      display: flex;
      flex-direction: column; }
      .modal .modal-content .comment-form textarea {
        resize: none;
        height: 150px; }

.comment-div {
  background: #282c34;
  color: #fff;
  border-radius: 5px;
  padding: 15px; }
  .comment-div .comment-content {
    text-align: left; }

.home-page {
  display: flex;
  justify-content: center; }
  .home-page__center {
    padding: 20px;
    margin-top: 50px;
    text-align: center; }
  .home-page__img {
    height: 200px;
    margin-right: 10px; }
    .home-page__img img {
      height: 100%; }
  .home-page .welcome-info h2 {
    background: -webkit-linear-gradient(340deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
    background: linear-gradient(110deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    font-size: 4em;
    line-height: 1em;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 0;
    width: 300px; }
  .home-page .welcome-info p {
    font-weight: 500;
    font-size: 1.5em;
    width: 400px;
    opacity: .8;
    margin: 50px 0; }
  .home-page .login-button {
    display: inline-block;
    padding: 10px 0;
    background-color: #222;
    color: #fff;
    width: 300px;
    font-weight: bold;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #eee;
    display: inline-flex;
    justify-content: center;
    align-items: center; }
  .home-page .register-call {
    display: inline-block;
    width: 300px;
    font-size: .9em;
    opacity: .8;
    cursor: pointer;
    border-top: 1px solid #eee;
    padding-top: 20px;
    margin-top: 40px; }

.form-holder--login {
  margin: 0 auto;
  display: inline-block;
  width: 300px;
  border-radius: 4px;
  color: #222; }

.login__header {
  font-size: 3em;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #eee; }

.login__error-holder {
  margin-bottom: 10px;
  text-align: center;
  height: 40px;
  color: red;
  font-weight: 400; }

.login__inputs-holder {
  margin-bottom: 50px; }

.login__holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px; }

.login__under-belly {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  opacity: .8; }

.login__under-txt {
  font-weight: 500;
  margin: 0;
  font-size: .8em;
  display: inline-block;
  color: #222; }

.login__register-btn {
  display: inline-block;
  font-size: .8em;
  position: relative;
  color: #222;
  text-decoration: none;
  font-weight: 500;
  margin-left: 5px; }

.form-holder--registation {
  width: 400px;
  padding: 20px;
  margin: 50px; }

.registration__holder {
  display: flex;
  justify-content: center; }

.wrapper {
  border-radius: 10px;
  overflow: hidden; }

.followingDiv {
  height: 120px;
  width: 120px;
  display: flex;
  color: #fff;
  background-color: #282c34;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  margin: 0 1%; }
  .followingDiv span, .followingDiv p {
    display: inline-flex;
    vertical-align: middle; }
  .followingDiv .follow-pic {
    text-transform: capitalize;
    align-items: center;
    justify-content: center;
    background: -webkit-linear-gradient(left, #29d0be 0%, #6cb8ea 100%);
    background: linear-gradient(90deg, #29d0be 0%, #6cb8ea 100%);
    border-radius: 5px;
    height: 50px;
    width: 50px;
    vertical-align: middle;
    overflow: hidden; }
    .followingDiv .follow-pic img {
      height: 100%;
      width: 100%;
      object-fit: cover; }

.bold {
  font-weight: bold;
  margin: 0 5px; }

.selected {
  background-color: #fff;
  color: #282c34; }

.follow-btn {
  cursor: pointer;
  margin: 10px;
  padding: 0 5px;
  border-radius: 5px; }
  .follow-btn:hover {
    background-color: #fff;
    color: #282c34; }

.profile-main-holder {
  padding: 25px; }

.profile-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  color: #fff;
  background-color: #282c34; }
  .profile-header .profile-pic-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin: 0 20px; }
  .profile-header .profile-name {
    text-align: center;
    margin-top: 10px; }
  .profile-header .profile-pic {
    display: flex;
    background: -webkit-linear-gradient(left, #29d0be 0%, #6cb8ea 100%);
    background: linear-gradient(90deg, #29d0be 0%, #6cb8ea 100%);
    align-items: center;
    justify-content: center;
    height: 125px;
    width: 125px;
    margin: 0 15px;
    border-radius: 15px;
    font-size: 50px;
    text-transform: capitalize;
    overflow: hidden; }
    .profile-header .profile-pic img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .profile-header .profile-info {
    line-height: 25px; }
    .profile-header .profile-info .profile-bio, .profile-header .profile-info .profile-stats, .profile-header .profile-info .profile-follow {
      margin: 7px 0; }
    .profile-header .profile-info .profile-follow {
      display: inline-block; }
      .profile-header .profile-info .profile-follow p {
        margin: 5px 0;
        display: inline-block; }
  .profile-header p, .profile-header h3 {
    margin: 0; }

.tab-holder {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border: 2px solid #282c34; }
  .tab-holder .active {
    color: white;
    background: -webkit-linear-gradient(left, #29d0be 0%, #6cb8ea 100%);
    background: linear-gradient(90deg, #29d0be 0%, #6cb8ea 100%); }
  .tab-holder p {
    cursor: pointer;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 15px;
    text-align: center;
    display: inline-block; }

.reviewDiv {
  width: 100%; }

.followContainer {
  display: flex; }

.followHolder {
  display: flex;
  padding: 10px; }

.edit {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px; }

.watchlist__button {
  cursor: pointer;
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: bold;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  justify-content: space-between;
  margin-bottom: 10px;
  -webkit-transition: box-shadow .1s ease-in-out;
  transition: box-shadow .1s ease-in-out; }
  .watchlist__button svg {
    height: 20px; }
  .watchlist__button.add {
    background: -webkit-linear-gradient(left, #29d0be 0%, #6cb8ea 100%);
    background: linear-gradient(90deg, #29d0be 0%, #6cb8ea 100%);
    color: #fff; }
  .watchlist__button.remove {
    background-color: #ff5959;
    color: #fff; }
  .watchlist__button:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
  .watchlist__button:active {
    box-shadow: none; }
  @media (max-width: 768px) {
    .watchlist__button {
      width: 100%;
      margin-bottom: 0; } }

.watchlist-item {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 250px;
  vertical-align: top; }
  .watchlist-item .back-item {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1; }
    .watchlist-item .back-item img {
      width: 110%;
      -webkit-transform: translate(-5%, -20%);
              transform: translate(-5%, -20%);
      -webkit-filter: blur(40px);
              filter: blur(40px); }
  .watchlist-item__info-holder {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 20px; }
    .watchlist-item__info-holder .poster__holder {
      height: 0%;
      flex: 0 0 auto;
      width: 140px;
      height: 100%;
      margin-right: 20px; }
      .watchlist-item__info-holder .poster__holder img {
        border-radius: 4px;
        height: 100%;
        width: 100%;
        object-fit: cover;
        box-shadow: 10px 10px 20px rgba(34, 34, 34, 0.5); }
    .watchlist-item__info-holder .meta-data {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .watchlist-item__info-holder .meta-data__title {
        color: #fff;
        font-weight: bold;
        margin: 0;
        font-size: 1.8em; }

.movie-top {
  position: relative;
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px; }
  .movie-top .movie-top--graphic {
    display: flex; }
    @media (max-width: 768px) {
      .movie-top .movie-top--graphic {
        flex-direction: column; } }
  .movie-top .movie-graphic {
    position: relative;
    flex: 1 1 auto;
    border-radius: 4px;
    display: flex;
    overflow: hidden;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .movie-top .movie-graphic img {
      width: 100%; }
    .movie-top .movie-graphic .no-image {
      background: -webkit-linear-gradient(340deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
      background: linear-gradient(110deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
      height: 35vw;
      width: 100%;
      min-height: 200px; }
    .movie-top .movie-graphic__title {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 10px;
      font-weight: bold;
      color: #fff;
      overflow: hidden; }
      .movie-top .movie-graphic__title p {
        position: relative;
        z-index: 11;
        font-size: 3em;
        margin: 0; }
        @media (max-width: 768px) {
          .movie-top .movie-graphic__title p {
            font-size: 4vw; } }
      .movie-top .movie-graphic__title img {
        position: absolute;
        width: 120%;
        left: -10%;
        bottom: -15%;
        z-index: 10;
        -webkit-filter: blur(10px);
                filter: blur(10px); }
  .movie-top .movie__buttons {
    height: auto;
    width: 200px;
    flex: 0 0 200px;
    padding-left: 10px; }
    @media (max-width: 768px) {
      .movie-top .movie__buttons {
        padding: 10px 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex: 0 0 auto; } }
    .movie-top .movie__buttons .row--top {
      flex: 1 1 50%; }
      @media (max-width: 768px) {
        .movie-top .movie__buttons .row--top {
          margin-right: 5px; } }
    .movie-top .movie__buttons .row--bottom {
      flex: 1 1 50%; }
      @media (max-width: 768px) {
        .movie-top .movie__buttons .row--bottom {
          margin-left: 5px; } }
    .movie-top .movie__buttons .button {
      cursor: pointer;
      height: auto;
      width: 100%;
      padding: 10px 20px;
      border-radius: 4px;
      font-weight: bold;
      font-size: 1em;
      margin-bottom: 10px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      -webkit-transition: box-shadow .1s ease-in-out;
      transition: box-shadow .1s ease-in-out; }
      @media (max-width: 768px) {
        .movie-top .movie__buttons .button {
          width: 100%; } }
      .movie-top .movie__buttons .button svg {
        height: 20px;
        flex: 0 0 auto; }
      .movie-top .movie__buttons .button p {
        margin: 0; }
      .movie-top .movie__buttons .button:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
      .movie-top .movie__buttons .button:active {
        box-shadow: none; }
      .movie-top .movie__buttons .button.watchlist {
        background-color: #29d0be;
        color: #fff;
        font-size: 1em; }
      .movie-top .movie__buttons .button.review {
        font-size: 1em;
        color: #222; }
      .movie-top .movie__buttons .button.trailer {
        color: #fff;
        background: -webkit-linear-gradient(left, #6cb8ea 0%, #ff5959 100%);
        background: linear-gradient(90deg, #6cb8ea 0%, #ff5959 100%); }

.review__button {
  cursor: pointer;
  width: 100%;
  padding: 10px 20px;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1em;
  justify-content: center;
  background-color: #fff; }
  .review__button__outline {
    width: 100%;
    -webkit-transition: box-shadow .1s ease-in-out;
    transition: box-shadow .1s ease-in-out;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    overflow: hidden;
    position: relative; }
    .review__button__outline:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }
    .review__button__outline:active {
      box-shadow: none; }
    .review__button__outline:after {
      content: "";
      height: 300%;
      width: 300%;
      position: absolute;
      left: 0;
      top: 0;
      background: -webkit-linear-gradient(left, #e1f549 10%, #29d0be 30%, #6cb8ea 60%, #ff5959 80%);
      background: linear-gradient(90deg, #e1f549 10%, #29d0be 30%, #6cb8ea 60%, #ff5959 80%);
      z-index: -1;
      -webkit-animation: border 4s ease-in;
              animation: border 4s ease-in;
      -webkit-animation-iteration-count: infinite;
              animation-iteration-count: infinite; }
    @media (max-width: 768px) {
      .review__button__outline {
        height: 100%; } }
  @media (max-width: 768px) {
    .review__button {
      height: 100%; } }
  .review__button.loading {
    justify-content: center; }
  .review__button.my-review {
    flex-direction: column; }
    .review__button.my-review .title {
      margin: 0;
      font-size: 1.2em; }
    .review__button.my-review .score {
      font-size: 4em;
      font-weight: bold;
      margin: 0; }
  @media (max-width: 768px) {
    .review__button {
      width: 100%; } }

@-webkit-keyframes border {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  100% {
    ransform: translate(0, 0); } }

@keyframes border {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  50% {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  100% {
    ransform: translate(0, 0); } }

.review__center {
  text-align: center; }

.no-review-msg {
  font-weight: bold;
  font-size: 1.4em; }

.review-form {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  text-align: left;
  display: inline-block;
  width: 500px;
  border: 1px solid #eee;
  padding: 20px 20px;
  border-radius: 4px;
  position: relative;
  overflow: hidden; }
  .review-form__title {
    font-weight: bold;
    font-size: 1.4em;
    margin: 0 0 20px 0; }
  .review-form__movie-breakdown {
    position: relative;
    width: 100%;
    margin: 0 0 auto;
    padding: 10px 0;
    margin-bottom: 20px; }
    .review-form__movie-breakdown .poster-holder {
      height: 300px;
      margin-bottom: 20px;
      text-align: center; }
      .review-form__movie-breakdown .poster-holder img {
        height: 300px;
        border-radius: 4px;
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
    .review-form__movie-breakdown .title-holder {
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: 20px; }
      .review-form__movie-breakdown .title-holder p {
        margin: 0; }
  .review-form__content__score {
    margin: 0 0 20px 0; }
    .review-form__content__score p {
      font-weight: bold;
      font-size: 1.3em;
      margin: 0 0 20px 0;
      text-transform: capitalize; }
    .review-form__content__score input {
      width: 100%;
      cursor: pointer; }
  .review-form__content__comment {
    margin: 0 0 20px 0; }
    .review-form__content__comment p {
      font-weight: bold;
      font-size: 1.3em;
      margin: 0 0 5px 0; }
    .review-form__content__comment textarea {
      width: 100%;
      resize: none;
      height: 100px;
      border: 1px solid #eee;
      border-radius: 4px;
      padding: 10px;
      font-size: 1em;
      outline: none; }
      .review-form__content__comment textarea:active {
        outline: none; }
      .review-form__content__comment textarea:focus {
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.kritiq-loader {
  position: relative;
  display: inline-block; }
  .kritiq-loader__title {
    font-weight: bold;
    font-size: 1.2em;
    margin: 0; }
    .kritiq-loader__title::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      bottom: -2px;
      left: 0;
      background: -webkit-linear-gradient(340deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
      background: linear-gradient(110deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
      -webkit-transform-origin: bottom left;
              transform-origin: bottom left;
      -webkit-transition: -webkit-transform 0.25s ease-out;
      transition: -webkit-transform 0.25s ease-out;
      transition: transform 0.25s ease-out;
      transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
      z-index: -1;
      border-radius: 3px;
      -webkit-animation: underlineDash 3s infinite;
              animation: underlineDash 3s infinite; }

@-webkit-keyframes underlineDash {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  24% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left; }
  25% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right; }
  50% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  74% {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right; }
  75% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left; }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); } }

@keyframes underlineDash {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  24% {
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left; }
  25% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right; }
  50% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  74% {
    -webkit-transform-origin: bottom right;
            transform-origin: bottom right; }
  75% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: bottom left;
            transform-origin: bottom left; }
  100% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0); } }

.review-view {
  text-align: center; }

.mrgn-rt {
  margin-right: 50px; }

.movie-review {
  margin: 20px 0; }

.movie-view__backdrop {
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: absolute; }
  .movie-view__backdrop::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    opacity: .7; }
  .movie-view__backdrop img {
    z-index: -1;
    height: 110%;
    width: 110%;
    object-fit: cover;
    -webkit-transform: translate(-5%, -5%);
            transform: translate(-5%, -5%);
    -webkit-filter: blur(50px);
            filter: blur(50px); }

.media-tile {
  flex: 1 1 auto;
  display: inline-block;
  cursor: pointer; }
  .media-tile img {
    height: 200px;
    width: auto; }
  .media-tile .poster__holder {
    width: 100%;
    height: 25vw;
    max-height: 300px;
    position: relative;
    display: flex;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    background-color: #eee;
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out; }
    @media (max-width: 768px) {
      .media-tile .poster__holder {
        height: 50vw; } }
    @media (max-width: 525px) {
      .media-tile .poster__holder {
        height: 70vw; } }
    .media-tile .poster__holder img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .media-tile .title-holder {
    margin-top: 5px;
    width: 133px;
    font-size: .8em;
    font-weight: bold; }

.media-tile--loader {
  flex: 0 0 auto;
  height: 200px;
  width: 133px;
  background-color: #eee;
  margin-right: 20px;
  border-radius: 4px; }

.h-list {
  width: 100%;
  margin-bottom: 30px;
  overflow-x: hidden; }
  .h-list .title {
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 0; }
  .h-list__topbar {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .h-list__scroll-buttons {
    display: flex; }
    .h-list__scroll-buttons .scroll__button {
      cursor: pointer;
      height: 50px;
      width: 50px;
      background-color: #eee;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px; }
      .h-list__scroll-buttons .scroll__button.disabled {
        opacity: .2;
        cursor: auto;
        cursor: initial;
        pointer-events: none; }
  .h-list .list__holder {
    width: 100%;
    padding: 10px 0;
    display: flex;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
    .h-list .list__holder::-webkit-scrollbar {
      width: 0 !important; }

.item-spacer {
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  justify-content: center;
  padding: 10px; }
  @media (max-width: 768px) {
    .item-spacer {
      width: 33.33%; } }
  @media (max-width: 525px) {
    .item-spacer {
      width: 50%; } }

.user-card {
  height: 100px;
  width: 250px;
  margin-right: 30px;
  border-radius: 10px;
  display: flex;
  padding: 10px;
  border: 1px solid #eee;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer; }
  .user-card .profile__img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    background-color: #eee; }
  .user-card .profile__data {
    margin-left: 10px; }
    .user-card .profile__data--username {
      margin: 0; }

.genre-card {
  position: relative;
  height: 100px;
  width: 150px;
  margin-right: 20px;
  margin-bottom: 10px;
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-left: 1px;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
  background-color: #fff; }
  .genre-card:hover {
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1); }
  .genre-card__grad-bar {
    position: absolute;
    width: 100%;
    height: 5px;
    top: 0;
    left: 0;
    background: -webkit-linear-gradient(340deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
    background: linear-gradient(110deg, #e1f549, #29d0be, #6cb8ea, #ff5959); }
  .genre-card__name {
    margin: 0;
    font-weight: bold;
    letter-spacing: .5px;
    color: #222; }

.cast-card {
  flex: 0 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow: hidden; }
  .cast-card .image__holder {
    width: 100%;
    height: 25vw;
    max-height: 300px;
    overflow: hidden;
    border-radius: 10px;
    background-color: #eee;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    -webkit-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out; }
    @media (max-width: 768px) {
      .cast-card .image__holder {
        height: 50vw; } }
    @media (max-width: 525px) {
      .cast-card .image__holder {
        height: 70vw; } }
    .cast-card .image__holder img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .cast-card .data__holder {
    height: 80px; }
    .cast-card .data__holder__name {
      margin: 5px 0;
      font-size: .9em;
      font-weight: 500; }
    .cast-card .data__holder__role {
      margin: 0;
      font-size: .8em;
      font-weight: 500;
      opacity: .3; }

.movie-info__section {
  margin-bottom: 20px; }

.movie-info__title {
  margin-top: 0;
  font-size: 2em;
  font-weight: bold; }

.info-card {
  flex: 0 0 auto;
  display: inline-block;
  min-width: 200px;
  padding: 10px;
  border: 1px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px; }
  .info-card p {
    margin: 0; }
  .info-card.large {
    max-width: 350px;
    justify-content: flex-start; }
  .info-card .title {
    font-weight: bold;
    font-size: 1.5em; }
  .info-card .value {
    text-align: right;
    font-size: 3em;
    font-weight: bold; }
  .info-card .summary {
    margin-top: 5px; }
  .info-card .image-holder {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center; }
    .info-card .image-holder img {
      width: 75%;
      height: auto;
      text-align: center;
      flex: 0 0 auto; }
  .info-card .pill__holder {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .info-card .pill__holder .pill {
      display: inline-block;
      border-radius: 4px;
      width: 100%;
      text-align: right;
      margin-bottom: 5px; }

.list-holder {
  display: flex;
  padding-bottom: 20px;
  flex-flow: wrap; }
  .list-holder .poster {
    flex: 0 0 auto;
    height: 200px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 20px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .list-holder .poster img {
      height: 100%; }

.side-list__wrapper {
  border: 1px solid red;
  margin: 10px 0;
  padding: 50px 0; }

.side-list__topbar {
  border: 1px solid blue;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.side-list__title {
  font-size: 1.5em;
  font-weight: bold; }

.side-list__scroll-buttons {
  display: flex; }

.side-list__list {
  border: 1px solid green;
  min-height: 100px; }

.search-page .search-input__holder {
  padding-top: 50px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }

.search-page .search-input__input {
  min-width: 500px;
  font-size: 2.5em;
  text-align: center;
  height: 55px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  font-size: 1.4em; }
  .search-page .search-input__input:active {
    outline: 0; }

.search-page .search__button {
  position: relative;
  font-weight: bold;
  font-size: 1.5em;
  border-radius: 4px;
  cursor: pointer;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 100px;
  background-color: #6cb8ea;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin-left: 10px; }
  .search-page .search__button:hover {
    opacity: 1; }

.search-page .results-title {
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 50px; }

.search-page .no-results {
  text-align: center;
  font-size: 20px; }


.movies-banner {
  position: relative;
  height: 300px;
  margin-bottom: 50px;
  width: 80;
  overflow: hidden; }
  .movies-banner__slide {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    display: inline-block;
    height: 200px;
    width: 400px;
    position: absolute;
    border-radius: 4px;
    background-color: #eee;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    cursor: pointer; }
    .movies-banner__slide--backdrop {
      height: 100%;
      width: 100%; }
      .movies-banner__slide--backdrop img {
        height: 100%;
        width: 100%;
        object-fit: cover; }
    .movies-banner__slide--title {
      display: none;
      position: absolute;
      left: 0;
      bottom: 0;
      overflow: hidden;
      width: 100%; }
      .movies-banner__slide--title .poster--blur {
        position: absolute;
        bottom: -100%;
        left: -5%;
        z-index: 99; }
        .movies-banner__slide--title .poster--blur img {
          width: 110%;
          -webkit-filter: blur(10px);
                  filter: blur(10px); }
      .movies-banner__slide--title p {
        position: relative;
        z-index: 100;
        margin: 0;
        color: #fff;
        font-weight: bold;
        font-size: 1.5em;
        padding: 10px; }
    .movies-banner__slide.none {
      display: none; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .movies-banner__slide.center {
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: 250px;
      width: 450px;
      z-index: 100; }
      @media (max-width: 768px) {
        .movies-banner__slide.center {
          width: 100%; } }
      .movies-banner__slide.center .poster--blur {
        -webkit-animation: fadeIn 1s;
                animation: fadeIn 1s; }
      .movies-banner__slide.center .movies-banner__slide--title {
        display: inline-block;
        -webkit-animation: fadeIn 1s;
                animation: fadeIn 1s; }
    .movies-banner__slide.left {
      left: 43%;
      top: 55%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      z-index: 99; }
    .movies-banner__slide.left-secondary {
      left: 40%;
      top: 55%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: 185px;
      z-index: 90; }
    .movies-banner__slide.left-hide {
      left: 40%;
      top: 55%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: 185px;
      opacity: 0;
      z-index: 80; }
    .movies-banner__slide.right {
      left: 57%;
      top: 55%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      z-index: 99; }
    .movies-banner__slide.right-secondary {
      z-index: 98;
      left: 60%;
      top: 55%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      height: 185px; }
    .movies-banner__slide.right-hide {
      z-index: 80;
      left: 60%;
      top: 55%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      opacity: 0;
      height: 185px; }
  .movies-banner .arrow {
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
    .movies-banner .arrow:hover {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .movies-banner .arrow--left {
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .movies-banner .arrow--right {
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }

.discover__title {
  margin-bottom: 20px;
  display: inline-block;
  font-size: 2em;
  font-weight: bold; }
  .discover__title p {
    margin: 0 0 5px 0; }

.discover .movie-list__holder {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 200px)); }

.discover .movie-tile__holder {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 250px;
  vertical-align: top; }

.filter__holder {
  border-bottom: 1px solid #eee;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }

.btn__holder {
  margin-bottom: 5px; }
  .btn__holder i {
    margin-right: 5px; }
  .btn__holder p {
    font-weight: 600;
    margin: 0; }

.filters.hide {
  display: none; }

.filters__holder {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px; }

.filters__title {
  display: inline-block;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  margin: 5px 0; }

.filters__section p {
  margin: 0;
  line-height: 2em;
  cursor: pointer; }

.actor-view-page .top-hold {
  display: flex;
  margin-bottom: 20px; }

.actor-view-page .img-holder {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  height: 304px;
  width: 200px; }
  .actor-view-page .img-holder img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.actor-view-page .data-holder {
  margin-left: 20px; }
  .actor-view-page .data-holder p {
    margin: 0; }

.actor-view-page .actor--name {
  font-size: 2em;
  font-weight: bold; }

.account-breakdown {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center; }
  .account-breakdown__username {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    margin-left: 10px; }

.account-settings__holder {
  display: flex; }

.account-settings__navigation {
  flex: 0 0 auto;
  width: 200px; }
  .account-settings__navigation .navigation__box {
    height: auto;
    width: 100%;
    border: 1px solid #eee;
    padding: 10px 0;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    .account-settings__navigation .navigation__box__title {
      margin: 0;
      padding-left: 10px;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 1px solid #eee;
      margin-bottom: 5px; }
    .account-settings__navigation .navigation__box__item {
      padding: 5px 10px; }
      .account-settings__navigation .navigation__box__item:hover {
        background-color: #eee;
        cursor: pointer; }

.account-settings__view {
  flex: 1 1 auto; }

.change-password {
  padding-left: 20px; }
  .change-password__title {
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px; }
  .change-password .btn__holder {
    text-align: center; }

.profile__view .user-info {
  display: flex;
  justify-content: center;
  margin-top: 20px; }
  .profile__view .user-info__wrapper {
    height: 300px;
    width: 400px;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.topbar {
  padding-right: 10px;
  display: flex;
  background-color: #100E0F;
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px; }
  .topbar__left-side {
    display: flex;
    align-items: center; }
  .topbar__logo {
    margin-right: 20px;
    font-size: 1.8em;
    font-weight: bold;
    text-align: center;
    position: relative;
    display: flex;
    cursor: pointer;
    text-decoration: none; }
    .topbar__logo span {
      align-items: bottom;
      font-size: .4em; }
    .topbar__logo:active, .topbar__logo:visited, .topbar__logo a:focus, .topbar__logo a:link {
      color: inherit;
      text-decoration: none; }
  .topbar__navLinks {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .topbar__navLinks__link {
      position: relative;
      font-size: 1em;
      text-decoration: none;
      font-weight: 700;
      color: #fff;
      margin-right: 10px; }
      .topbar__navLinks__link:active, .topbar__navLinks__link:visited, .topbar__navLinks__link a:focus, .topbar__navLinks__link a:link {
        color: inherit;
        text-decoration: none; }
  .topbar__search {
    flex: 1 1 auto;
    display: flex;
    align-items: center; }
    .topbar__search input {
      height: 100%;
      width: 100%;
      padding: 0 10px;
      font-size: 1.3em;
      font-weight: bold;
      border: none; }
      .topbar__search input::-webkit-input-placeholder {
        opacity: .5; }
      .topbar__search input::-ms-input-placeholder {
        opacity: .5; }
      .topbar__search input::placeholder {
        opacity: .5; }
      .topbar__search input:active, .topbar__search input:focus {
        outline: none; }
  .topbar__user-info {
    width: auto;
    display: flex;
    align-items: center; }
    .topbar__user-info .loginRegister {
      display: inherit;
      cursor: pointer; }
  .topbar__login-btn {
    position: relative;
    color: #fff;
    font-weight: 600;
    cursor: pointer; }
    .topbar__login-btn p {
      color: #fff; }

.profile-icon {
  height: 40px;
  width: 40px;
  background-color: #eee;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; }
  .profile-icon p {
    color: #222;
    font-size: 1em;
    font-weight: 600; }
  .profile-icon img {
    height: 100%;
    width: 100%;
    object-fit: cover; }

.utils--dropdown {
  display: none;
  position: absolute;
  height: auto;
  width: auto;
  min-width: 150px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  right: 20px;
  bottom: -10px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  padding: 10px 0;
  background-color: #fff; }
  .utils--dropdown.show {
    display: inline-block; }
  .utils--dropdown .item {
    cursor: pointer;
    width: auto;
    color: #222;
    font-weight: bold;
    padding: 5px 10px; }
    .utils--dropdown .item:hover {
      background-color: #eee; }

.page-view {
  position: relative;
  padding-top: 60px;
  padding-left: 0; }
  .page-view--topbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 80; }
  .page-view--sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    z-index: 100;
    background-color: #fff;
    border: 1px solid #eee;
    overflow: hidden; }
    @media (max-width: 767px) {
      .page-view--sidebar {
        width: 0; } }
  .page-view--main-view {
    position: relative;
    z-index: 50; }

html {
  scroll-behavior: smooth; }

* {
  box-sizing: inherit; }

body {
  font-family: 'NHass', sans-serif;
  box-sizing: border-box; }

.gradient {
  background-color: #21D4FD;
  background-image: -webkit-linear-gradient(340deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
  background-image: linear-gradient(110deg, #e1f549, #29d0be, #6cb8ea, #ff5959); }

.grad-bar {
  height: 5px;
  width: 100%;
  border-radius: 4px; }

.view-page {
  position: relative;
  padding: 50px;
  margin: 0 auto;
  max-width: 1200px;
  z-index: 100; }
  @media (max-width: 992px) {
    .view-page {
      padding: 20px; } }
  @media (max-width: 525px) {
    .view-page {
      padding: 5px; } }

.err-msg {
  color: rgba(255, 0, 0, 0.6); }

.btn-icon {
  margin-right: 5px; }

.underline--gradient::after {
  content: '';
  position: absolute;
  width: 100%;
  background: -webkit-linear-gradient(340deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
  background: linear-gradient(110deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
  height: 3px;
  bottom: 0;
  left: 0;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  z-index: -1;
  border-radius: 10px; }

.card-1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  border-radius: 4px; }

.form-1 {
  padding-top: 15px;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }
  .form-1 h3 {
    margin-bottom: 10px;
    margin-top: 0; }
  .form-1 .bar {
    width: 100%;
    height: 5px;
    position: relative;
    border-radius: 10px;
    margin-bottom: 20px; }
  .form-1 .input-holder {
    margin-bottom: 20px;
    position: relative; }
    .form-1 .input-holder .floating-label {
      position: absolute;
      top: 40%;
      left: 10px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      pointer-events: none;
      -webkit-transition: 0.2s ease all;
      transition: 0.2s ease all;
      background-color: #fff; }
    .form-1 .input-holder input, .form-1 .input-holder textarea {
      border-radius: 4px;
      border: 1px solid #eee;
      height: auto;
      width: 100%;
      font-size: 1em;
      padding: 10px 10px;
      margin-bottom: 10px; }
      .form-1 .input-holder input:focus, .form-1 .input-holder input:active, .form-1 .input-holder textarea:focus, .form-1 .input-holder textarea:active {
        outline: none; }
      .form-1 .input-holder input:focus ~ .floating-label, .form-1 .input-holder textarea:focus ~ .floating-label {
        top: -2px;
        font-size: .8em; }
      .form-1 .input-holder input:not(:focus):valid ~ .floating-label, .form-1 .input-holder textarea:not(:focus):valid ~ .floating-label {
        top: -2px;
        font-size: .8em; }
  .form-1 .submit-button {
    width: 100%;
    height: 30px;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
    text-align: center;
    line-height: 30px;
    margin-top: 10px;
    border-radius: 4px;
    color: white;
    font-weight: 700;
    cursor: pointer;
    -webkit-transition: .1s ease-in;
    transition: .1s ease-in; }

.btn p {
  margin: 0; }

.btn.login-btn {
  color: #fff; }

.btn.standard {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold !important;
  cursor: pointer;
  border-radius: 4px; }
  .btn.standard i {
    margin-right: 5px; }

.btn.underline-grad::after {
  content: '';
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background: -webkit-linear-gradient(340deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
  background: linear-gradient(110deg, #e1f549, #29d0be, #6cb8ea, #ff5959);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  -webkit-transition: -webkit-transform 0.25s ease-out;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
  z-index: -1;
  border-radius: 1px; }

.btn.underline-grad.active::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1); }

.btn.underline-grad:hover::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left; }

.btn.underline-grad:active::after, .btn.underline-grad:focus::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left; }

.btn.primary {
  background-color: #2196f3;
  color: #fff; }
  .btn.primary:hover {
    background-color: #2196f3; }

.btn.shadow {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.btn.background-gradient {
  background-color: #21D4FD;
  background-image: -webkit-linear-gradient(21deg, #21D4FD 0%, #B721FF 100%);
  background-image: linear-gradient(69deg, #21D4FD 0%, #B721FF 100%);
  color: #fff; }

.button__standard {
  width: 300px;
  background-color: #222;
  color: #fff;
  padding: 10px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.loader--movie-tile {
  height: 200px;
  width: 133px;
  display: inline-block;
  background-color: #eee;
  border-radius: 4px; }

.filler {
  background-color: #eee;
  width: 100%; }
  .filler.text {
    height: 20px;
    border-radius: 10px; }
  .filler.half {
    width: 50%; }
  .filler.med {
    width: 200px; }
  .filler.large {
    width: 400px; }

.input__standard {
  position: relative;
  margin-bottom: 20px; }
  .input__standard .floating-label {
    position: absolute;
    top: 40%;
    left: 10px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
    pointer-events: none;
    -webkit-transition: 0.2s ease all;
    transition: 0.2s ease all;
    background-color: #fff;
    color: #6f6f6f;
    text-transform: capitalize; }
  .input__standard input {
    border-radius: 4px;
    border: 1px solid #eee;
    height: auto;
    width: 100%;
    font-size: 1em;
    padding: 10px 10px;
    margin-bottom: 10px;
    outline: none; }
    .input__standard input:focus ~ .floating-label,
    .input__standard input:valid ~ .floating-label {
      top: -2px;
      font-size: .8em; }

