


@import '../../Styles/package';


.search-page {


  	.search-input{
		&__holder {
			padding-top: 50px;
			padding-bottom: 20px;
			// background-color: $color-black;
			display: flex;
			justify-content: center;
			align-items: center;
		}


		&__input {
			// background-color: $color-black;
			// color: #fff;
			min-width: 500px;
			font-size: 2.5em;
			text-align: center;
	
            height: 55px;
            box-shadow: $box-shadow-1;
			padding: 10px;
            border: 1px solid #eee;
            border-radius: 4px;
            font-size: 1.4em;

			&:active {
				outline: 0;
			}
		}
	  }


	  .search {


		&__button {
			
			position: relative;
			
			
			font-weight: bold;
			font-size: 1.5em;

			border-radius: 4px;
			cursor: pointer;
			height: 100%;
            color: #fff;
            
            display:flex; 
        
            align-items: center;
            justify-content: center;

            height: 55px;
            width: 100px;

            // background: $gradient;
            background-color: $blue;
            box-shadow: $box-shadow-1;
            margin-left:10px;

			&:hover {opacity: 1;}


		}
	  }


	  .results-title {
		  font-size: 1.5em;
		  font-weight: bold;
		  padding-left: 50px;
	  }

    .no-results {
      text-align: center;
      font-size: 20px
    }

}
