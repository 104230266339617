
@import '../../../../Styles/package';



.kritiq-loader {
    position: relative;
    
    display: inline-block;

    &__title {
        font-weight: bold;
        font-size: 1.2em;
        margin: 0;
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            
            height: 3px;
            bottom: -2px;
            left: 0;
            background: $gradient;
            transform-origin: bottom left;
            transition: transform 0.25s ease-out;
            z-index: -1;
            border-radius: 3px;
           animation: underlineDash 3s infinite;
        }
    }
}


@keyframes underlineDash {
    0%{ transform: scaleX(0);}
    
    
    24% {transform-origin: bottom left;}
    25% {
        transform: scaleX(1);
        transform-origin: bottom right;
    }

    50% {
        transform: scaleX(0);
       
    }
    74% {transform-origin: bottom right;}
    75% {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
    100% {
        transform: scaleX(0);
        
    }
}


// &.active::after {
//     transform: scaleX(1);
// }
// &:hover::after {
//     transform: scaleX(1);
//     transform-origin: bottom left;
// }
// &:active::after, &:focus::after {
//     transform: scaleX(1);
//     transform-origin: bottom left;
// }