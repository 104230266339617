
@import '../../../Styles/package';

.watchlist-item {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 250px;
    vertical-align: top;


    .back-item {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: -1;

        img {
            width: 110%;
            transform: translate(-5%, -20%);
            filter: blur(40px);
        }
    }



    &__info-holder {
        display: flex;
      
        height: 100%;
        width: 100%;
        padding: 20px;
        .poster__holder {
            height: 0%;
            flex: 0 0 auto;
            width: 140px;
            height: 100%;
            margin-right: 20px;
            
            img {
                
                border-radius: 4px;
                height: 100%;
                width: 100%;
                object-fit: cover;
                box-shadow: 10px 10px 20px rgba(#222,  .5);
            }
        }


        .meta-data {
            width: 100%;
            // border: 1px solid blue;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            
            &__title {
                color: #fff;
                font-weight: bold;
                margin: 0;
                font-size: 1.8em;
            }
        }   
    }


}