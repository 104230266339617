
@import '../../../Styles/package';

.review__center {
    text-align: center;
    
}
.no-review-msg {
    font-weight: bold;
    font-size: 1.4em;
}
.review-form {
    box-shadow: $box-shadow-1;
    text-align: left;
    display: inline-block;
    width: 500px;
    border: 1px solid #eee;
    padding: 20px 20px;
    border-radius: 4px;
    position: relative;

    overflow: hidden;
    

    &__title {
        font-weight: bold;
        font-size: 1.4em;
        margin: 0 0 20px 0 ;
    }

    &__movie-breakdown {
        position: relative;
    
    
        width: 100%;
        margin: 0 0 auto;
        padding: 10px 0;
        margin-bottom: 20px;
      

        .poster-holder {
            height: 300px;
            // border: 1px solid red;
            margin-bottom: 20px;
            text-align: center;
            img {
                height: 300px; 
               
                border-radius: 4px;
                box-shadow: $box-shadow-poster;
            }
        }
        .title-holder {
            font-size: 1.5em;
            font-weight: bold;
            margin-bottom: 20px;
            p{margin: 0}
            
        }
    }

    &__content {
        &__score {
            margin: 0 0 20px 0;
            p{
                font-weight: bold;
                font-size: 1.3em;
                margin: 0 0 20px 0;
                text-transform: capitalize;
              
            }
            input {width: 100%; cursor: pointer;}
        }
        &__comment {
            margin: 0 0 20px 0;
            p{ font-weight: bold;
                font-size: 1.3em;
                margin: 0 0 5px 0;}

            textarea {
                width: 100%;
                resize: none;
                height: 100px;
                border: 1px solid #eee;
                border-radius: 4px;
                padding: 10px;
                font-size: 1em;
                outline: none;

                &:active {outline: none; }
                &:focus {box-shadow: $box-shadow-1;}
            }
        }
    }
}