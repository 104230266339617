

@import '../../../../Styles/package';

.movies-banner  {
    // border: 1px solid blue;
    position: relative;
    height:300px;


    // border: 1px solid red;

    margin-bottom: 50px;
    width: 80;
    overflow: hidden;
    


    &__slide {
        transition: all .2s ease-in-out;
        display: inline-block;
        height: 200px;
        width: 400px;
        position: absolute;
        border-radius: 4px;
        background-color: #eee;
        box-shadow: $box-shadow-1;
        overflow: hidden;
        cursor: pointer;



        &--backdrop {
            height: 100%;
            width:100%;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &--title {

            display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            overflow: hidden;
            width: 100%;

            .poster--blur {
                position: absolute;
                bottom: -100%;
                left: -5%;
                z-index: 99;
                img {
                    width: 110%;
                    filter: blur(10px);

                }
            }
            p{
                position: relative;
                z-index: 100;
                margin: 0;
                color: #fff;
                font-weight: bold;
                font-size: 1.5em;
                padding: 10px;
            }
        }


        &.none {display: none;}




        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        &.center {
            left: 50%;
            top:50%;
            transform: translate(-50%, -50%);
            height: 250px;
            width: 450px;
            z-index: 100;

            @include breakpoint(sm) {
                width: 100%;
            }
            
            .poster--blur {
                animation: fadeIn 1s;
            }
            .movies-banner__slide--title{
                display: inline-block;
                animation: fadeIn 1s;
            }


        }
        &.left {
            left:43%;
            top:55%;
            transform: translate(-50%, -50%);
            z-index: 99;

        }

        &.left-secondary {
            left:40%;
            top:55%;
            transform: translate(-50%, -50%);
            height: 185px;

            z-index: 90;
        }

        &.left-hide {
            left:40%;
            top:55%;
            transform: translate(-50%, -50%);
            height: 185px;
            opacity: 0;
            z-index: 80;
        }
        &.right {
            left:57%;
            top:55%;
            transform: translate(-50%, -50%);

            z-index: 99;
        }
        &.right-secondary {
            z-index: 98;
            left:60%;
            top:55%;
            transform: translate(-50%, -50%);
            height: 185px;

        }

        &.right-hide {
            z-index: 80;
            left:60%;
            top:55%;
            transform: translate(-50%, -50%);
            opacity: 0;
            height: 185px;
        }
    }


    .arrow  {
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50px;
        background-color: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        // transition: all .1s ease-in-out;
        &:hover {

            box-shadow: $box-shadow-1;
        }
        &--left {
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &--right {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
