

@import '../../../../Styles/package';


.cast-card {
    flex: 0 0 auto;
    height: 100%;
    width: 100%;
    // margin-right: 20px;


    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    
    .image__holder {
        
        width: 100%;
        height: 25vw;
        max-height: 300px;
        overflow: hidden;
        border-radius: 10px;
        background-color: #eee;
        box-shadow: $box-shadow-1;
        transition: all .1s ease-in-out;
        @include breakpoint(sm) {
            height: 50vw;
        }
        @include breakpoint(xxs) {
            height: 70vw;
        }
        img{
            height: 100%;
            width: 100%;
            
            object-fit: cover;
        }
    }


    .data__holder {
        // border: 1px solid red;
        height: 80px;
        &__name {
            margin: 5px 0;
            font-size: .9em;
            font-weight: 500;
        }
        &__role {
            margin: 0;
            font-size: .8em;
            font-weight: 500;
            opacity: .3;
        }
    }

}