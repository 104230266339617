
@import '../../../../Styles/package';




.profile__view {
    

    .user-info {
        display: flex;
        justify-content: center;
        margin-top: 20px;
       
        &__wrapper {
           
            height:300px; width: 400px;   
            
            border-radius: 4px;
            box-shadow: $box-shadow-1;
        }
    }
}