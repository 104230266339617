

.form-1 {
  padding-top: 15px;
    backface-visibility: hidden;
    h3 { margin-bottom: 10px; margin-top: 0;}

    .bar{
        width: 100%;
        height: 5px;
        position: relative;
        border-radius: 10px;
        margin-bottom: 20px;
    }


    .input-holder {
        margin-bottom: 20px;
        position: relative;
        .floating-label {
            position: absolute;
            top: 40%;
            left:10px;
            transform: translate(0, -50%);
            pointer-events: none;
            transition: 0.2s ease all;
            background-color: #fff;


        }

        input, textarea {
            border-radius: 4px;
            border: 1px solid #eee;
            height: auto;
            width: 100%;
            font-size: 1em;
            padding: 10px 10px;
            margin-bottom: 10px;
            &:focus, &:active {
                outline: none;
            }
            &:focus ~ .floating-label {
                top: -2px;
                font-size: .8em;
            }

            &:not(:focus):valid  ~ .floating-label {
                top: -2px;
                font-size: .8em;
            }
        }



    }

    .submit-button {
        width: 100%;
        height: 30px;
        position: relative;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 0 0 40px rgba(0, 0, 0, 0.06) inset;
        text-align: center;
        line-height: 30px;
        margin-top: 10px;
        border-radius: 4px;
        color: white;
        font-weight: 700;
        cursor: pointer;
        transition: .1s ease-in;
    }

} //end form
