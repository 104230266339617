@import '../../../Styles/package';

.discover { 


    
    &__title {
        margin-bottom: 20px;
        display: inline-block;
        font-size: 2em;
        font-weight: bold;
        p{margin: 0 0 5px 0}
       
    }

    .movie-list__holder{
        
        margin-top: 10px;
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(130px, 200px) );
        
    }

    .movie-tile__holder {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 250px;
        vertical-align: top;
    }

}