
@import '../../Styles/package';



.account-breakdown {
    
    width: 100%;
    // border: 1px solid red;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    &__username {
        margin: 0;
        font-size: 1.5em;
        font-weight: bold;
        margin-left: 10px;
    }
}
.account-settings {
    &__holder {
        display: flex;
    }

    &__navigation {
        
        flex: 0 0 auto;
        width: 200px;

        .navigation__box {
            height: auto;
            width: 100%;
            border:1px solid #eee;
            padding: 10px 0;
            border-radius: 4px;
            box-shadow: $box-shadow-1;


            &__title {
                margin: 0;
                padding-left: 10px;
                font-weight: bold;
                padding-bottom: 5px;
                border-bottom: 1px solid #eee;
                margin-bottom: 5px;
            }

            &__item {
                padding: 5px 10px;


                &:hover {
                    background-color: #eee;
                    cursor: pointer
                }
            }
        }
    }
    &__view {
        // border: 1px solid blue;
        flex: 1 1 auto;
    }
}