@import '../../../../styles/package';

.review-card {

    position: relative;
    height : 300px;
    width: 500px;
    background-color: #fff;

    display: inline-flex;
    flex-direction: column;
    border-radius: 4px;

    padding: 15px;
    text-align: left;;
    box-shadow: $box-shadow-1;
    margin: 10px;
    cursor: pointer;


    &__user-info {
        // border: 1px solid blue;

        display: flex;
        align-items: center;

        margin-bottom: 15px;

        &__profile-icon {
            height: 50px;
            width: 50px;
            margin-right: 15px;
            cursor: pointer;
        }

        &__user-txt {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            flex: 1 1 auto;

              &--username {
                  margin: 0;
                  font-weight: bold;
                  margin-bottom: 5px

              }
              &--date {
                  margin: 0;
                  font-size: .8em;
                  opacity: .5;
              }
        }
    }


    &__review-info {

        flex: 1 1 auto;
        display: flex;

        &__score {


            height: 100%;
            width: 50px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &--data {
                font-size: 1.8em;
                font-weight: bold;
                margin: 0;
            }
        }

        &__movie-poster {
            flex: 0 0 auto;
            margin-right: 15px;
            height: 100%;
            width: 140px;
            border-radius: 4px;
            background-color: #eee;
            box-shadow: $box-shadow-1;
            overflow: hidden;
            cursor: pointer;

            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }


        &__content {

            &--header {
                font-weight: bold;
                text-transform: capitalize;
                margin: 0 0 15px 0;

                font-size: 1.2em;
            }
            &--body {
                margin: 0;
            }
            &--comment {
              margin: 10px 20px;
              position: absolute;
              bottom: 0;
              right: 0;
              i {
                margin-left: 4px;
              }
            }
        }
    }


}





// .review-card {


//     height: auto;

//     margin-bottom: 20px;
//     display: flex;
//     // align-items: baseline;


//     &__user {
//         &--img {
//             flex: 0 0 auto;
//             margin-right: 10px;
//             display: flex;
//             align-self: flex-end;;

//         }

//         &--username {
//             // font-weight: bold;
//             font-size: .8em;
//             opacity: .2;
//             padding-left: 5px;
//         }
//     }


//     &__bubble {
//         &--info {
//             cursor: pointer;
//             position: relative;
//             padding: 10px ;
//             border-radius: 10px;
//             background-color: #eee;
//             width: 400px;
//             display: flex;

//             &:before {
//                 content:"";
//                 position:absolute;
//                 z-index:-1;
//                 bottom:-2px;
//                 left:-7px;
//                 height:20px;
//                 border-left:20px solid #eee;
//                 border-bottom-right-radius: 16px 14px;
//                 -webkit-transform:translate(0, -2px);
//             }

//             &:after {
//                 content:"";
//                 position:absolute;
//                 z-index:-1;
//                 bottom:-2px;
//                 left:4px;
//                 width:26px;
//                 height:20px;
//                 background:white;
//                 border-bottom-right-radius: 10px;
//                 -webkit-transform:translate(-30px, -2px);
//             }



//             .poster__holder {
//                 height: 100px;

//                 flex: 0 0 auto;
//                 overflow: hidden;
//                 &.hide {display: none}
//                 // border: 1px solid red;


//                 img {
//                     height: 100%;
//                     border-radius: 4px;
//                 }
//             }
//             .review-data {
//                 flex: 1 1 auto;
//                 // border: 1px solid blue;

//                 padding-left: 5px;
//                 p{margin: 0;}

//                 &--score {
//                     font-weight: bold;
//                     margin-bottom: 5px !important;

//                 }
//                 &--content {
//                     font-weight: 400;
//                     font-size: .8em;
//                     opacity: 1;
//                 }
//             }
//         }
//     }
// }
