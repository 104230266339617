@import '../../../styles/package';


.user-icon {
  &:hover {
    box-shadow: $box-shadow-3;
  }
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    background: linear-gradient(90deg, $green 0%, $blue 100%);
    color: #fff;
    text-transform: capitalize;
    overflow: hidden;
    p{
        font-weight: bold;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
}
