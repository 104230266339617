



.movie-view__backdrop  {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: absolute;



    &::before {
        content : '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #fff;
        z-index: 1;
        opacity: .7;
    }

    img{
        z-index: -1;
        height: 110%;
        width: 110%;
        object-fit: cover;
        transform: translate(-5%, -5%);
        filter: blur(50px);
    
    }
}