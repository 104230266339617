@import '../../../Styles/package';





.review__button {

    &__outline {
        width: 100%;
        transition: box-shadow .1s ease-in-out;
        box-shadow: $box-shadow-1;
        // border: 1px solid red;
        border-radius: 4px;
        &:hover {box-shadow: $box-shadow-3;}
        &:active {box-shadow: none; }
        padding-top: 4px;
        padding-bottom: 4px;
        overflow: hidden;
        position: relative;


        &:after {
            content:"";
            height: 300%;
            width: 300%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(90deg,$color-primary-yellow 10%,$color-primary-green 30%,$color-primary-blue 60%,$color-primary-red 80%) ;
            z-index: -1;
            animation:  border 4s ease-in;
            animation-iteration-count: infinite;

        }

        @include breakpoint(sm) {
            // border: 1px solid red;
            height: 100%;
           
        }
    }

    cursor: pointer;
    width: 100%;
    padding: 10px 20px;
    @include breakpoint(sm) {
        // border: 1px solid red;
        height: 100%;
    }
    
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: bold;
   
    display: flex;
    align-items: center;
    font-size: 1em;
    justify-content: center;

    background-color: #fff;
    

    &.loading {
        justify-content: center;
    }


    &.my-review {
        flex-direction: column;


        .title {
            margin: 0;
            font-size: 1.2em;
            
            // &:after {    
            //     content: '';
            //     display: inline-block;
            //     height: 5px;
            //     border-radius: 5px;
            //     width: 100%;
            //     background: $gradient;
            //     z-index: 10;
          
            // }
        }

        .score {
            font-size: 4em;
            font-weight: bold;;
            margin: 0;
            // @include gradientText($gradient);
        }
    }
    

    @include breakpoint(sm) {
        width: 100%;
    }
}


@keyframes border {
    0% {
        transform: translate(0, 0)
    }
    50%{
        transform: translate(-50%, -50%)
    }
    100%{
        ransform: translate(0, 0)
    }
}