


$color-black: #100E0F ;




$color-primary-blue: #6cb8ea;
$color-primary-yellow: #e1f549;
$color-primary-green: #29d0be;
$color-primary-red: #ff5959;

$green: $color-primary-green;
$blue: $color-primary-blue;
$red: $color-primary-red;
$yellow: $color-primary-yellow;

$gradient: linear-gradient(110deg,$color-primary-yellow,$color-primary-green,$color-primary-blue,$color-primary-red);



$gradient-1 : linear-gradient(90deg, #21D4FD 0%, #B721FF 100%);


$color-primary: #2196f3;


$box-shadow-1: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
$box-shadow-2: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$box-shadow-3: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
$box-shadow-poster:  0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);