

  

html {
    scroll-behavior: smooth;
}
* {box-sizing: inherit}
body { font-family: 'NHass', sans-serif;box-sizing: border-box;}
  

