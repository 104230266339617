@import '../../../../Styles/package';




.h-list {
    width: 100%;
    margin-bottom: 30px;
    overflow-x: hidden;

    .title {
        
        font-size: 1.5em;
        font-weight: bold;
        margin-top: 0;
        
    }



    &__topbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        
    }

    &__scroll-buttons {
        display: flex;
        .scroll__button {
            cursor: pointer;
            height: 50px;
            width:50px;
            // padding: 20px;
            background-color: #eee;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;

            &.disabled {
                opacity: .2;
                cursor: initial;
                pointer-events: none;
            }
        }
    }

    .list__holder {
        width: 100%;
        
        padding: 10px 0;
        display: flex;
        
        
        transition: all .2s ease-out;
        
        &::-webkit-scrollbar { width: 0 !important }

        
    }


}


.item-spacer {
        flex: 0 0 auto;
        // border: 1px solid red;
        width: 20%;
        display: flex;
        justify-content: center;
        padding: 10px;

        

        @include breakpoint(sm) {
            width: 33.33%
        }
        
        @include breakpoint(xxs) {
            width: 50%
        }
        
}