@import '../../../Styles/package';


.profile-icon {
    height: 40px; width: 40px;
    background-color: #eee;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    p {
      color: #222;
      font-size:1em;
      font-weight: 600;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
}


.utils--dropdown  {

    &.show {display: inline-block;}
    display: none;
    position: absolute;
    height: auto;
    width: auto;
    min-width: 150px;
    border-radius: 4px;
    box-shadow: $box-shadow-1;
    right: 20px; bottom: -10px;
    transform: translateY(100%);
    padding: 10px 0;
    background-color: #fff;


    .item {

        cursor: pointer;
        width: auto;
        color: #222;
        font-weight: bold;
        padding: 5px 10px;
        &:hover {
            background-color: #eee;
        }
    }

}
