

@import '../package';




.gradient {
    background-color: #21D4FD;
    background-image: linear-gradient(110deg,#e1f549,#29d0be,#6cb8ea,#ff5959)
}

// .space-black {
//     background-color: $color-black;
//     background-image: $noise;
// }


.grad-bar {
    height: 5px;
    width: 100%;
    border-radius: 4px;

}

.view-page {
    position: relative;
    padding: 50px;
    margin: 0 auto;
    max-width: 1200px;
    z-index: 100;
    @include breakpoint(md) {
        // border:1px solid red;
        padding: 20px;
    }
    @include breakpoint(xxs) {
        padding: 5px;
    }


}

.err-msg {
    color: rgba($color: red, $alpha: .6);
}


.btn-icon {
    margin-right: 5px;
}






.underline {
   
    &--gradient {
       
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            // transform: scaleX(0);
            background: $gradient;
            height:3px;
            bottom: 0;
            left: 0;
            transform-origin: bottom left;
            transition: transform 0.25s ease-out;
            z-index: -1;
            border-radius: 10px;
           
        }
    }
}