

@import '../../../../Styles/package';


.genre-card {
    position: relative;
    height: 100px;
    width: 150px;
    margin-right: 20px;
    margin-bottom: 10px;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: $box-shadow-1;
    // border: 1px solid #eee;
    margin-left: 1px;
    transition: transform .2s ease-in-out;
    background-color: #fff;
   
    &:hover {
        transform: scale(1.1, 1.1)
    }
    &__grad-bar {
        position: absolute;
        width: 100%;
        height: 5px;
        top: 0; left: 0;
        background: $gradient;
    }


    &__name {
        margin: 0;
        font-weight: bold;
        letter-spacing: .5px;
        color: #222;
    }

}   
