
@import '../../Styles/package';

.home-page {

    display: flex;
    justify-content: center;
    


    &__center {
        
        
        padding: 20px;
        margin-top: 50px;
    
        text-align: center;
    }

    &__img  {
        height: 200px;
        // width: 50px;
        // border: 1px solid red;
        margin-right: 10px;
        img {
            height: 100%;;
        }
    }


    .welcome-info {
        h2 {
            @include gradientText($gradient);
            display: inline-block;
            font-size: 4em;
            line-height: 1em;;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;
            margin-bottom: 0;
            width: 300px;
        }
        p  { 
            font-weight: 500;
            font-size: 1.5em;;
            width:400px;
            opacity: .8;
            margin: 50px 0;
        }
    }

    .login-button {
        display: inline-block;
        padding: 10px 0;
        background-color: #222;
        color: #fff;
        width: 300px;
        font-weight: bold;
        border-radius: 50px;
        cursor: pointer;
        box-shadow: 1px 1px 1px #eee;

        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    .register-call {
        display: inline-block;
        width: 300px;
        font-size: .9em;
        opacity: .8;
        cursor: pointer;
        border-top: 1px solid #eee;
        padding-top: 20px;
        margin-top: 40px;
    }
}



