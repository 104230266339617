

@import '../../../../../Styles/package';



.movie-info {
    &__section {
        // border: 1px solid red;
        margin-bottom: 20px;
    }
    &__title {
        margin-top: 0;
        font-size: 2em;
        font-weight: bold;
    }

}

.info-card {
    flex: 0 0 auto;
    display: inline-block;
    min-width: 200px;
    padding: 10px;
    p{margin: 0;}
    border: 1px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: $box-shadow-1;
    border-radius: 4px;
    margin-right: 20px;
    margin-bottom: 20px;

    &.large {
        max-width: 350px;
        justify-content: flex-start;
    }
    .title {
        font-weight: bold;
        font-size: 1.5em;
    }

    .value{

        // border: 1px solid red;
        text-align: right;
        font-size: 3em;
        font-weight: bold;
    }
    .summary {
        margin-top: 5px;
        // border: 
    }
    .image-holder {
        // border: 1px solid red;
        // height: 50%;
        width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        
        img {width: 75%; height: auto;text-align: center; flex: 0 0 auto;}
    }
    .pill__holder {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .pill{
            // border: 1px solid green;s
            display: inline-block;
            border-radius: 4px;
            width: 100%;
            text-align: right;
            margin-bottom: 5px;
        }
    }
}



.list-holder {
    display: flex;
    padding-bottom: 20px;
    flex-flow: wrap;
    .poster {
        flex:  0 0 auto;
        height: 200px;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 20px;

        box-shadow: $box-shadow-1;
        img{
            height: 100%;}
    }
}


