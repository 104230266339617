
//the styles for inputs 

.input {

    &__standard{
        position: relative ;
        margin-bottom: 20px;


        .floating-label {
            position: absolute;
            top: 40%;
            left:10px;
            transform: translate(0, -50%);
            pointer-events: none;
            transition: 0.2s ease all;
            background-color: #fff;
            color: darken(#eee, 50%);
            text-transform: capitalize;
        
        }
        
        input {
        
            border-radius: 4px;
            border: 1px solid #eee;
            height: auto;
            width: 100%;
            font-size: 1em;
            padding: 10px 10px;
            margin-bottom: 10px;
            outline: none;


            &:focus ~ .floating-label,
            &:valid ~ .floating-label {
                top: -2px;
                font-size: .8em;
            }
    
          
        }
    }
}