
@import '../../Styles/package';



.watchlist__button {
    cursor: pointer;
    width: 100%;
    padding: 10px 20px;
    
    border-radius: 4px;
    text-transform: capitalize;
    font-weight: bold;
    box-shadow: $box-shadow-1;
    display: inline-flex;
    align-items: center;
    font-size: 1em;
    justify-content: space-between;
    margin-bottom: 10px;
    transition: box-shadow .1s ease-in-out;

    svg {height: 20px;}

    &.add {
        background:linear-gradient(90deg, $green 0%, $blue 100%);
        color: #fff;
    }
    &.remove {
        background-color: $red;
        color: #fff;
    }

    &:hover {box-shadow: $box-shadow-3;}
    &:active {box-shadow: none; }


    @include breakpoint(sm) {
        width: 100%;
        margin-bottom: 0;
    }
}