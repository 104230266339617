.modal {
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

  .modal-content {
    .close-icon {
      margin: 0;
      font-size: 30px;
      cursor: pointer;
      text-align: right;
    }

    .comment-form {
      display: flex;
      flex-direction: column;
      textarea {
        resize: none;
        height: 150px;
      }
    }

    background-color: #fefefe;
    border-radius: 10px;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    // border: 1px solid #888;
    width: 600px; /* Could be more or less, depending on screen size */
  }
}

.comment-div {
  background: #282c34;
  color: #fff;
  // border: 2px solid #282c34;
  border-radius: 5px;
  padding: 15px;

  .comment-content {
    text-align: left;
  }
}
