

@import '../../../../Styles/package';


.actor-view-page {

    .top-hold {

        display: flex;
        // border: 1px solid red;
        margin-bottom: 20px;

    }
    .img-holder {
        
        border-radius: 10px;
        overflow: hidden;
        box-shadow: $box-shadow-1;
        height: 304px;
        width: 200px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }   

    .data-holder {
        margin-left: 20px;
        p {margin: 0;}

    
    }


    .actor {
        &--name {
            font-size: 2em;
            font-weight: bold;
        }
    }



}