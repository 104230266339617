

@import '../../../../Styles/package';




$font-color: #222;


.movie-top {
    position: relative;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    

    .movie-top--graphic {
        // border: 1px solid blue;
        display: flex;
    

        @include breakpoint(sm) {
            // border: 1px solid red;
            flex-direction: column;
        }
        
    }


    

    .movie-graphic{
        // border: 1px solid red;
        position: relative;
        flex: 1 1 auto;
        border-radius: 4px;
        display:flex;
        overflow: hidden;
        box-shadow: $box-shadow-1;

        img {width: 100%;}

        .no-image {
            background: $gradient;
            height: 35vw;
            width: 100%;
            min-height: 200px;
        }
        &__title {
            // border: 1px solid red;
            position: absolute;
            left: 0; bottom: 0;
            width: 100%;
            padding: 10px;
           
            font-weight: bold;
            color: #fff;
            overflow: hidden;
            p {
                position: relative; 
                z-index: 11; 
                font-size: 3em; 
                margin: 0;
                @include breakpoint(sm) {
                    font-size: 4vw;
                }
            }
            img {
                position: absolute;
                width: 120%;
                // height: 10%;
                left: -10%;
                bottom: -15%;
                z-index: 10;
                filter: blur(10px);
            }
        }
    }



    .movie__buttons {
        height: auto;
        width: 200px;
        flex: 0 0 200px;
       
        padding-left:10px;


        @include breakpoint(sm) {
            padding: 10px 0px;
            width: 100%;
            display: flex;  
            justify-content: space-between;  
            flex: 0 0 auto;
            
            
            
        }

        .row {
            &--top {
                flex: 1 1 50%;
                @include breakpoint(sm) {
                    margin-right: 5px;
                }
            }
            &--bottom {
                flex: 1 1 50%;
                @include breakpoint(sm) {
                    margin-left: 5px;

                }
            }
        }

        .button {
            cursor: pointer;
            height: auto;
            width: 100%;;
            // bordeR: 1px solid blue;
            padding: 10px 20px;
            border-radius: 4px;
            font-weight: bold;
            font-size: 1em;
            margin-bottom: 10px;    
        
             
            @include breakpoint(sm) {
                
                width: 100%;
            }
          
            box-shadow: $box-shadow-1;

            svg {height: 20px;  flex: 0 0 auto}
            p {margin: 0;}

            display: inline-flex;
            align-items: center;
            justify-content: space-between;

            transition: box-shadow .1s ease-in-out;
            &:hover {box-shadow: $box-shadow-3;}
            &:active {box-shadow: none; }
            &.watchlist {
                
                background-color: $green;
                color: #fff;
                font-size: 1em;
            }


            &.review {
                
                font-size: 1em;   
                color: #222;     
              

            }
            &.trailer {
                color: #fff;
                background: linear-gradient(90deg, $blue 0%, $red 100%)
            }




        }
    }
}