
@import '../../../Styles/package';

.media-tile  {
    flex:  1 1 auto;
    display: inline-block;
  
    cursor: pointer;

    img {
        height: 200px; 
        width: auto;
    }


    .poster {
        &__holder {
           
            width:100%;
            height: 25vw;
            max-height: 300px;

            position: relative;
            display: flex;
            border-radius: 4px;
            box-shadow: $box-shadow-1;
            overflow: hidden;
            background-color: #eee;
            transition: all .1s ease-in-out;

            @include breakpoint(sm) {
                height: 50vw;
            }
            @include breakpoint(xxs) {
                height: 70vw;
            }

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .title-holder {
        margin-top: 5px;
        width: 133px;
        font-size: .8em;
        font-weight: bold;
      
    }
}


.media-tile--loader {
    flex: 0 0 auto;
    height: 200px;
    width: 133px;
    background-color: #eee;
    margin-right: 20px;
    border-radius: 4px;
}