@import '../../../../Styles/package';

.form-holder--login {
    margin: 0 auto;
    display: inline-block;
    width:  300px;
    border-radius: 4px; 
    color: #222;

}


.login__form {
    // border: 1px solid #eee;



    
}

.login {
    &__form {}
    &__header {
        font-size: 3em;
        margin: 0 0 20px 0;
        border-bottom: 1px solid #eee;
    }
    &__error-holder {
        margin-bottom: 10px;
        text-align: center;;
        height: 40px;
        color: red;
        font-weight: 400;
    }
   

    &__inputs-holder {
        margin-bottom: 50px;
    }
}