@import '../../../Styles/package';

.user-card {
    height: 100px;
    width: 250px;
    
    margin-right: 30px;
    border-radius: 10px;
    display: flex;
    padding: 10px;
    border: 1px solid #eee;

    box-shadow: $box-shadow-1;
    cursor: pointer;
    .profile{
        &__img {
            height: 80px;width: 80px;
            
            border-radius: 10px;
            background-color: #eee;
        }
        &__data {
            margin-left: 10px;
            &--username {
                margin: 0;

            }
        }
    }
}