@import '../../package';



.btn {
    p {margin: 0;}
    &.login-btn {color: #fff;}
    &.standard {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-weight: bold !important;
      
        cursor: pointer;
        border-radius: 4px;

        i {
            margin-right:5px;
        }
    }

    &.underline-grad {
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 3px;
            bottom: 0;
            left: 0;
            background: $gradient;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
            z-index: -1;
            border-radius: 1px;
           
        }
        &.active::after {
            transform: scaleX(1);
        }
        &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
        &:active::after, &:focus::after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }


    &.primary {
        background-color: $color-primary;
        color: #fff;
        &:hover{
            background-color: $color-primary;
        }
    }
    &.shadow { 
        box-shadow: $box-shadow-1;
    }

    &.background-gradient {
        @include gradient();
        color: #fff;
    }



    &.flat-gradient-text {
       p:hover   {
         
       }
    }
}


.button {
    &__standard {
        width: 300px;
        background-color: #222;
        color: #fff;
        padding: 10px;
        cursor: pointer;
        font-weight: 500;
        border-radius: 50px;
        display: inline-flex;
        justify-content: center;
        align-items:center;
    }
}