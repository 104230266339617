

.change-password {

    padding-left: 20px;

    &__title {
        margin: 0;
        font-size: 1.5em;
        font-weight: bold;
        margin-bottom: 20px;
    }

    .btn__holder {
        text-align: center;
    }
}