


@import '../../../../Styles/package';



.side-list {


    &__wrapper {
        border: 1px solid red;
        margin: 10px 0 ;
        // min-height: 400px;
        padding: 50px 0;
    }


    &__topbar {
        border: 1px solid blue;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: 1.5em;
        font-weight: bold;
    }

    &__scroll-buttons {
        display: flex;

      
    }


    &__list {
        border: 1px solid green;
        min-height: 100px;
    }
    
}