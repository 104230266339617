
.filter__holder {
    border-bottom: 1px solid #eee;
    transition: all .2s ease-out;
}
.btn__holder{
    i {margin-right: 5px;}
    p {font-weight: 600; margin: 0;}
    margin-bottom: 5px;
}


.filters{
    &.hide {display: none;}
    &__holder {
       display: inline-block;
       vertical-align: top;
       margin-right: 10px;
    }
    &__title {
        display: inline-block;
        font-weight: bold;
        border-bottom: 1px solid #eee;
        margin: 5px 0;
    }
    &__section {
        p {margin:0; line-height: 2em; cursor: pointer;}
    }
}