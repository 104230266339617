@import '../../../Styles/package';


.topbar {

    padding-right: 10px;
    // border: 1px solid red !important;
    display: flex;
    background-color: $color-black;
    color: #fff;
    justify-content: space-between;

    align-items: center;
    padding:0 50px;
    

    &__left-side {
        display: flex;
        align-items: center;
    }



    &__logo {
        margin-right: 20px;
        font-size: 1.8em;
        font-weight: bold;
        text-align: center;
        position: relative;
        // border: 1px solid red;
        display: flex;

        cursor: pointer;
        text-decoration: none;
        span {
            align-items: bottom;
            font-size: .4em;

        }
        &:active, &:visited, a:focus, a:link {
            color: inherit;
            text-decoration: none;
        }

    }

    &__navLinks {
        display: flex;
        flex-direction: row;
        align-items: center;


        &__link {
            position: relative;
            font-size: 1em;
            text-decoration: none;
            font-weight: 700;
            color: #fff;
            margin-right: 10px;
            &:hover {
                // opacity: .5;
            }

            &:active, &:visited,a:focus, a:link {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    &__search {
        flex: 1 1 auto;
        display: flex;
        align-items: center;


        // border: 1px solid red;

        input {
            height: 100%;
            width: 100%;
            padding: 0 10px;
            font-size: 1.3em;
            font-weight: bold;
            border: none;
            &::placeholder {
                opacity: .5;
            }
            &:active, &:focus {
                outline: none;

            }

        }

    }

    &__user-info {
        width: auto;
        // border: 1px solid blue;
        display: flex;
        align-items: center;
        .loginRegister {
          display: inherit;
          cursor: pointer;
        }
    }


    &__login-btn {
        position: relative;
        color: #fff;
        
        font-weight: 600;
        cursor: pointer;
        p{ color: #fff;}
        
    }

}
