.login{
    
    &__holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 50px;
    }


    &__under-belly {
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-direction: column;
        margin-top: 10px;
        opacity: .8;
        
    }
    &__under-txt {
        font-weight: 500;
        margin: 0;
        font-size: .8em;
        display: inline-block;
        color: #222;

    }


    &__register-btn {
        display: inline-block;
        font-size: .8em;
        position: relative;
        color: #222;
        text-decoration: none;
        font-weight: 500;
        margin-left: 5px;
    }
}