@import '../../Styles/package';

.wrapper {
  border-radius: 10px;
  overflow: hidden;
  // border: 2px solid #282c34;
}
.followingDiv {
  height: 120px;
  width: 120px;
  display: flex;
  color: #fff;
  background-color: #282c34;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  margin: 0 1%;
  span, p {
    display: inline-flex;
    vertical-align: middle;
  }
  .follow-pic {
    text-transform: capitalize;
    // margin: 0 15px;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, $green 0%, $blue 100%);
    border-radius: 5px;
    height: 50px;
    width: 50px;
    vertical-align: middle;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.bold {
  font-weight: bold;
  margin: 0 5px;
}
.selected {
  background-color: #fff;
  color: #282c34;
}
.follow-btn {
  cursor: pointer;
  margin: 10px;
  padding: 0 5px;
  border-radius: 5px;
  &:hover {
    background-color: #fff;
    color: #282c34;
  }
}

.profile-main-holder {
  padding: 25px;
}

.profile-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  color: #fff;
  background-color: #282c34;

  .profile-pic-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    margin: 0 20px;
  }
  .profile-name {
    text-align: center;
    margin-top: 10px;
  }
  .profile-pic {
    display: flex;
    background: linear-gradient(90deg, $green 0%, $blue 100%);
    align-items: center;
    justify-content: center;
    height: 125px;
    width: 125px;
    margin: 0 15px;
    border-radius: 15px;
    font-size: 50px;
    text-transform: capitalize;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .profile-info {
    line-height: 25px;
    .profile-bio, .profile-stats, .profile-follow {
      margin: 7px 0;
    }
    .profile-follow {
      display: inline-block;
      p {
        margin: 5px 0;
        display: inline-block;
      }
    }
  }
  p, h3 {
    margin: 0;
  }
}

.tab-holder {
  width: 100%;
  display: flex;
  justify-content: space-around;
  border: 2px solid #282c34;
  .active {
    color: white;
    background: linear-gradient(90deg, $green 0%, $blue 100%);
  }
  p {
    cursor: pointer;
    // border-right: 2px solid #282c34;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 15px;
    text-align: center;
    display: inline-block;
  }
}
.reviewDiv {
  width: 100%;
}

.followContainer {
  display: flex;
}

.followHolder {
  display: flex;
  padding: 10px;
}

.edit {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
}
