
@import '../../package'; 

.loader {

    &--movie-tile {
        height: 200px;
        width: 133px;
        display: inline-block;
        background-color: #eee;
        border-radius: 4px;
    }
}



.filler {
    background-color: #eee;
    width: 100%;

    &.text {
        height: 20px;
        border-radius: 10px;
    }
    &.half {
        width: 50%;
    }

    &.med {
        width: 200px;
    }
    &.large {
        width: 400px;
    }
}