@mixin breakpoint($class) {

    @if $class == xxs {
        @media (max-width: 525px) { @content; }
    } 

    @if $class == xs {
        @media (max-width: 767px) { @content; }
    } 
    @if $class == minxs {
         @media (min-width: 767px) { @content; }
    }

    @else if $class == minsm {
        @media (min-width: 768px) { @content; }
    }
    @else if $class == sm {
        @media (max-width: 768px) { @content; }
    }
   
    @else if $class == md {
        @media (max-width: 992px) { @content; }
    }
    @else if $class == minmd {
        @media (min-width: 992px) { @content; }
    }
    @else if $class == lg {
        @media (max-width: 1200px) { @content; }
    }
    @else if $class == minlg {
         @media (min-width: 1200px) { @content; }
    }
    @else if $class == xxl {
        @media (min-width: 1300px) { @content; }
   }

   
    @else {
      @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}




@mixin gradient() {
    background-color: #21D4FD;
    background-image: linear-gradient(69deg, #21D4FD 0%, #B721FF 100%);
}

@mixin gradientText($gradient) {
    background: $gradient;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}