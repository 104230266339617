
@import '../../Styles/package';

.page-center {
    text-align: center;
}
.review-holder {

    text-align: center;
    width:auto;
    min-width: 800px;;
    display: inline-block;
    padding:0  20px;

    // border: 1px solid red;

}